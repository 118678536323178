import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PersonTile from "../components/personTile";
import SEO from "../components/seo";
import PageHero from "../components/page-hero";
import Fader from "../helper/Fader";

export const query = graphql`
  {
    allSanityPage(filter: { slug: { current: { eq: "people" } } }) {
      edges {
        node {
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          backgroundImage {
            asset {
              fixed(width: 1600, height: 900) {
                ...GatsbySanityImageFixed_withWebp_noBase64
              }
            }
          }
          title
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
    allSanityPerson(sort: { fields: order, order: ASC }) {
      nodes {
        id
        order
        fullName
        slug {
          current
        }
        role
        abbreviations
        practiceAreas {
          title
        }
        image {
          alt
          hotspot {
            x
            y
          }
          asset {
            fluid(maxWidth: 5000, maxHeight: 5000) {
              ...GatsbySanityImageFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

class OurPeople extends React.Component {
  render() {
    const page = this.props.data.allSanityPage.edges[0].node;
    const people = this.props.data.allSanityPerson.nodes;

    return (
      <Layout
        className="page-people-container"
        backgroundImage={page.backgroundImage?.asset?.fixed}
      >
        <SEO
          title={(page.pageSEO && page.pageSEO.metaTitle) || page.title}
          description={page.pageSEO && page.pageSEO.metaDescription}
          image={page.pageSEO && page.pageSEO.metaImage}
        />
        <PageHero title={page.title} intro={page._rawIntro} />
        <Fader>
          <section className="people-tiles">
            {people.map((person, i) => {
              return <PersonTile key={i} person={person} aspectRatio={4 / 3} />;
            })}
          </section>
        </Fader>
      </Layout>
    );
  }
}

export default OurPeople;
