import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import formatObjectPosition from "../helper/Image";

const PersonTile = ({ person, aspectRatio }) => {
  return (
    person &&
    person.image && (
      <Link
        to={`/people/${person.slug.current}`}
        className="person-tile-container"
      >
        <div className="person-tile-image-container">
          <Img
            className="person-tile-image"
            fluid={person.image.asset.fluid}
            alt={person.image.alt || person.fullName}
            objectFit="cover"
            objectPosition={formatObjectPosition(person.image.hotspot)}
            aspectRatio={aspectRatio}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div className="person-tile-content">
          <div className="person-tile-name-container">
            <h3 className="person-tile-name">{person.fullName}</h3>
          </div>
          {person.role && (
            <p className="person-tile-role">
              {person.abbreviations && (
                <span className="caption">{`${person.abbreviations} `}</span>
              )}
              {person.role}
            </p>
          )}
          {person.practiceAreas && (
            <p className="person-tile-practice">
              {person.practiceAreas.map((practice, i) => {
                if (i === 0) {
                  return practice.title;
                } else if (i === 1) {
                  return `, ${practice.title}`;
                } else if (i === 2) {
                  return " + more";
                } else {
                  return "";
                }
              })}
            </p>
          )}
        </div>
      </Link>
    )
  );
};

export default PersonTile;
